import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-list-payroll',
  templateUrl: './list-payroll.component.html',
  styleUrls: ['./list-payroll.component.scss'],
})
export class ListPayrollComponent implements OnInit {
  @ViewChild('createReportModal', { static: false })
  createReportModal!: TemplateRef<any>;
  createReportModalClose: any;
  breadcrumbs: any = [];
  payrolls: any = [];

  constructor(private api: ApiService, private modal: NgbModal) {}

  ngOnInit(): void {
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de Nominas',
        link: false,
      },
    ];

    this.loadPayrolls();
  }

  loadPayrolls() {
    this.api.get('payroll').subscribe({
      next: (payroll) => {
        this.payrolls = payroll.data;
      },
      error: (err) => {},
    });
  }

  openCreateReport(): void {
    this.createReportModalClose = this.modal.open(this.createReportModal, {
      size: 'lg',
    });
  }

  close(): void {
    this.createReportModalClose.close();
  }
}
