<div class="col d-grip gap-2 d-md-flex justify-content-md-end me-5">
  <div class="d-flex align-items-center me-5" style="min-height: 10vh">
    <div class="box w-100 me-3" style="margin-top: -300px">
      <!--div class="float-end text-capitalize fs-5 fw-bold"-->
      <strong>
        Bienvenido {{ onlyName }} (<span class="fst-italic">
          {{ officeName }} </span
        >)
      </strong>
      <button
        class="btn btn-primary ms-2 me-2"
        (click)="openChangeOffice()"
        *ngIf="roleKey == 'admin' || roleKey == 'rs' || hasSecondary"
      >
        Cambio de sucursal
      </button>
      <!--/div-->
      <a href="/logout" class="btn btn-primary" role="button">
        Cerrar sesi&oacute;n
      </a>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row flex-nowrap ms-5">
    <div class="col-auto col-md-3 col-xl-2">
      <div class="card bg-color-pink-ligth px-sm-2 px-0">
        <a
          href="/dashboard/calendar"
          class="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-black text-decoration-none"
        >
          <span class="mt-2 ms-2 fs-5 d-none d-sm-inline"> Men&uacute; </span>
        </a>
        <div
          class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white"
          *ngIf="roleKey == 'admin'"
        >
          <ul
            class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
            id="menu"
          >
            <li class="nav-item">
              <a href="/dashboard/calendar" class="nav-link align-middle px-0">
                <i class="fs-4 bi bi-calendar-date"></i>
                <span class="ms-1 d-none d-sm-inline"> Agenda </span>
              </a>
            </li>
            <li class="nav-item">
              <a href="/clients/list" class="nav-link align-middle px-0">
                <i class="fs-4 bi bi-people"></i>
                <span class="ms-1 d-none d-sm-inline"> Clientes </span>
              </a>
            </li>
            <li class="nav-item">
              <a href="/packages/list" class="nav-link align-middle px-0">
                <i class="fs-4 bi bi-box-seam"></i>
                <span class="ms-1 d-none d-sm-inline"> Paquetes </span>
              </a>
            </li>

            <li class="nav-item">
              <button
                class="btn btn-toggle nav-link align-items-center rounded collapsed px-0"
                data-bs-toggle="collapse"
                data-bs-target="#cobranza-collapse"
                aria-expanded="false"
              >
                <i class="fs-4 bi bi-bank"></i>
                <span class="ms-1 d-none d-sm-inline"> Cobranza </span>
                <i class="bi bi-caret-down-fill"></i>
              </button>
              <div class="collapse" id="cobranza-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 ms-4">
                  <li class="nav-item">
                    <a
                      href="/daily-sells/list"
                      class="link-dark d-inline-flex text-decoration-none rounded nav-link px-0 algn-middle"
                    >
                      <i class="bi bi-cash-coin"></i>
                      <span class="ms-1 d-none d-sm-inline">
                        Ventas diarias y por cobrar
                      </span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      href="/small-box/list"
                      class="link-dark d-inline-flex text-decoration-none rounded nav-link px-0 algn-middle"
                    >
                      <i class="bi bi-archive-fill"></i>
                      <span class="ms-1 d-none d-sm-inline">Caja chica</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      href="/black-debts/list"
                      class="link-dark d-inline-flex text-decoration-none rounded nav-link px-0 algn-middle"
                    >
                      <i class="bi bi-wallet2"></i>
                      <span class="ms-1 d-none d-sm-inline">Negritas</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      href="/weekly-sells/list"
                      class="link-dark d-inline-flex text-decoration-none rounded nav-link px-0 align-middle"
                    >
                      <i class="fs-4 bi bi-file-earmark-spreadsheet"></i>
                      <span class="ms-1 pt-2 d-none d-sm-inline">
                        Reportes
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item">
              <button
                class="btn btn-toggle nav-link align-items-center rounded collapsed px-0"
                data-bs-toggle="collapse"
                data-bs-target="#marketing-collapse"
                aria-expanded="false"
              >
                <i class="fs-4 bi bi-signal"></i>
                <span class="ms-1 d-none d-sm-inline"> Marketing </span>
                <i class="bi bi-caret-down-fill"></i>
              </button>
              <div class="collapse" id="marketing-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 ms-4">
                  <li class="nav-item">
                    <a
                      href="/prospects/list"
                      class="nav-link px-0 align-middle"
                    >
                      <i class="fs-4 bi bi-person-check-fill"></i>
                      <span class="ms-1 d-none d-sm-inline">Prospectos</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      href="/promotions/list"
                      class="nav-link px-0 algn-middle"
                    >
                      <i class="bi bi-percent"></i>
                      <span class="ms-1 d-none d-sm-inline">Promociones</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item">
              <button
                class="btn btn-toggle nav-link align-items-center rounded collapsed px-0"
                data-bs-toggle="collapse"
                data-bs-target="#payments-collapse"
                aria-expanded="false"
              >
                <i class="fs-4 bi bi-wallet-fill"></i>
                <span> Pagos </span>
                <i class="bi bi-caret-down-fill"></i>
              </button>
              <div class="collapse" id="payments-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 ms-4">
                  <li class="nav-item">
                    <a href="/payrolls/list" class="nav-link px-0 align-middle">
                      <i class="fs-4 bi bi-cash-coin"></i>
                      <span class="ms-1 d-none d-sm-inline"> Nominas </span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="/bonus/list" class="nav-link px-0 align-middle">
                      <i class="fs-4 bi bi-cash-coin"></i>
                      <span class="ms-1 d-none d-sm-inline">Bonos</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      href="/commissions/list"
                      class="nav-link px-0 align-middle"
                    >
                      <i class="fs-4 bi bi-cash-coin"></i>
                      <span class="ms-1 d-none d-sm-inline">Comisiones</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="/cycles/list" class="nav-link px-0 align-middle">
                      <i class="fs-4 bi bi-calendar3"></i>
                      <span class="ms-1 d-none d-sm-inline">
                        Periodos de pago
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item">
              <button
                class="btn btn-toggle nav-link align-items-center rounded collapsed px-0"
                data-bs-toggle="collapse"
                data-bs-target="#editable-collapse"
                aria-expanded="false"
              >
                <i class="fs-4 bi bi-pencil"></i>
                <span> Editables </span>
                <i class="bi bi-caret-down-fill"></i>
              </button>
              <div class="collapse" id="editable-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 ms-4">
                  <li class="nav-item">
                    <a
                      href="/employees/list"
                      class="nav-link px-0 align-middle"
                    >
                      <i class="fs-4 bi bi-person-badge"></i>
                      <span class="ms-1 d-none d-sm-inline">Colaboradores</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="/offices/list" class="nav-link px-0 align-middle">
                      <i class="fs-4 bi bi-house-door"></i>
                      <span class="ms-1 d-none d-sm-inline">Sucursales</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="/roles/list" class="nav-link px-0 align-middle">
                      <i class="fs-4 bi bi-person-rolodex"></i>
                      <span class="ms-1 d-none d-sm-inline"> Roles </span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="/cabins/list" class="nav-link px-0 align-middle">
                      <i class="fs-4 bi bi-building-check"></i>
                      <span class="ms-1 d-none d-sm-inline">Cabinas</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="/areas/list" class="nav-link align-middle px-0">
                      <i class="fs-4 bi bi-person-plus-fill"></i>
                      <span class="ms-1 d-none d-sm-inline"> Areas </span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="/products/list" class="nav-link px-0 align-middle">
                      <i class="fs-4 bi bi-cart-check"></i>
                      <span class="ms-1 d-none d-sm-inline">
                        Insumos en Venta
                      </span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      href="/payments-methods/list"
                      class="nav-link px-0 align-middle"
                    >
                      <i class="fs-4 bi bi-credit-card"></i>
                      <span class="ms-1 d-none d-sm-inline">
                        M&eacute;todos de pagos
                      </span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="/cities/list" class="nav-link px-0 align-middle">
                      <i class="fs-4 bi bi-building"></i>
                      <span class="ms-1 d-none d-sm-inline"> Ciudades </span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="/payments/list" class="nav-link px-0 align-middle">
                      <i class="fs-4 bi bi-credit-card"></i>
                      <span class="ms-1 d-none d-sm-inline"> Tipos Pagos </span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <div
          class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white"
          *ngIf="roleKey != 'admin'"
        >
          <ul
            class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
            id="menu"
          >
            <li class="nav-item">
              <a href="/dashboard/calendar" class="nav-link align-middle px-0">
                <i class="fs-4 bi bi-calendar-date"></i>
                <span class="ms-1 d-none d-sm-inline"> Agenda </span>
              </a>
            </li>
            <li class="nav-item">
              <a href="/cabins/list" class="nav-link px-0 align-middle">
                <i class="fs-4 bi bi-building-check"></i>
                <span class="ms-1 d-none d-sm-inline">Cabinas</span>
              </a>
            </li>
            <li class="nav-item">
              <a href="/packages/list" class="nav-link align-middle px-0">
                <i class="fs-4 bi bi-box-seam"></i>
                <span class="ms-1 d-none d-sm-inline"> Paquetes </span>
              </a>
            </li>
            <li class="nav-item">
              <a href="/clients/list" class="nav-link align-middle px-0">
                <i class="fs-4 bi bi-people"></i>
                <span class="ms-1 d-none d-sm-inline">Clientes</span>
              </a>
            </li>
            <li class="nav-item">
              <a href="/prospects/list" class="nav-link px-0 align-middle">
                <i class="fs-4 bi bi-person-check-fill"></i>
                <span class="ms-1 d-none d-sm-inline">Prospectos</span>
              </a>
            </li>
            <li class="nav-item">
              <a href="/small-box/list" class="nav-link px-0 algn-middle">
                <i class="bi bi-archive-fill"></i>
                <span class="ms-1 d-none d-sm-inline">Caja chica</span>
              </a>
            </li>
            <li class="nav-item">
              <a href="/black-debts/list" class="nav-link px-0 algn-middle">
                <i class="bi bi-wallet2"></i>
                <span class="ms-1 d-none d-sm-inline">Negritas</span>
              </a>
            </li>
            <!--li class="nav-item">
              <a href="/products/list" class="nav-link px-0 align-middle">
                <i class="fs-4 bi bi-cart-check"></i>
                <span class="ms-1 d-none d-sm-inline">Productos</span>
              </a>
            </li>
            <li class="nav-item">
              <a href="/gifts/list" class="nav-link px-0 align-middle">
                <i class="fs-4 bi bi-gift"></i>
                <span class="ms-1 d-none d-sm-inline">Premios</span>
              </a>
            </li>

            <li class="nav-item">
              <a href="/commissions/list" class="nav-link px-0 align-middle">
                <i class="fs-4 bi bi-percent"></i>
                <span class="ms-1 d-none d-sm-inline">Comisiones</span>
              </a>
            </li -->
          </ul>
        </div>
      </div>
    </div>

    <div class="col-md-9 ms-5">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<ng-template #changeOffice let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Cambio de Sucursal</h5>
    <button class="close" type="button" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="card mt-2">
      <div class="card-body">
        <form
          [formGroup]="changeForm"
          (ngSubmit)="reloadNewOffice()"
          novalidate
        >
          <div class="form-floating mb-3">
            <select
              name="office_id_change"
              id="office_id_change"
              class="form-control"
              formControlName="office_id_change"
              required
              [ngClass]="{
                'is-valid': submit && !cf.office_id_change.errors,
                'is-invalid': submit && cf.office_id_change.errors
              }"
            >
              <option selected value="">Selecciona una sucursal</option>
              <option value="{{ office.id }}" *ngFor="let office of offices">
                {{ office.name }}
              </option>
            </select>
            <label for="office_id_change" class="">
              Selecciona una sucursal
            </label>
          </div>
          <div class="row">
            <div class="d-grip gap-2 d-md-flex justify-content-md-end">
              <button type="submit" class="btn btn-outline-success">
                Cambiar sucursal
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
