import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-show-package',
  templateUrl: './show-package.component.html',
  styleUrls: ['./show-package.component.scss'],
})
export class ShowPackageComponent implements OnInit {
  @ViewChild('contractModal', { static: false })
  contractModal!: TemplateRef<any>;
  modalContractClose: any;
  breadcrumbs: any = [];
  paramId: string | null = '';
  formPackages: any = FormGroup;
  formatter: any;
  datePurchased: string = '';
  areasNames: any = [];
  dataAreas: any = [];
  columnsPayments: number = 12;
  terminalFlag: boolean = false;
  commentsSessions: any = [];
  imageContract: string = '';

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private modal: NgbModal
  ) {}

  ngOnInit(): void {
    this.formatter = new Intl.NumberFormat('en-US');
    this.paramId = this.route.snapshot.paramMap.get('id');
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de paquetes',
        path: '/packages/list',
        link: true,
      },
      {
        name: 'Informacion del paquete',
        link: false,
      },
    ];

    this.loadPackages();
    this.validations();
  }

  validations() {
    this.formPackages = this.fb.group({
      name: [''],
      areas: [''],
      sessions: [''],
      durations: [''],
      total: [''],
      discount: [''],
      applyDiscount: [''],
      custom_sessions: [''],
      paymentMethodId: [''],
      paymentId: [''],
      terminal: [''],
      observations: [''],
    });
  }

  getAreasInfo(areas: []): void {
    areas.forEach((data: any) => {
      this.dataAreas[data.sess_id] = data.number;
    });
  }

  getPaymentsInfo(data: any): void {
    this.terminalFlag = false;
    let sumInvalid: number = 1;
    console.log(data.terminal);
    if (data.terminal) {
      sumInvalid++;
      this.terminalFlag = true;
    }

    if (data.payment_name.length != 0) {
      sumInvalid++;
    }

    this.columnsPayments = this.columnsPayments / sumInvalid;
    this.formPackages.patchValue({
      paymentMethodId: data.payment_method_name,
      paymentId: data.payment_name,
      terminal: data.terminal ? data.terminal : '',
    });
  }

  get f() {
    return this.formPackages.controls;
  }

  loadPackages(): void {
    this.api.getById('packages/single', Number(this.paramId)).subscribe({
      next: (packages) => {
        const duration = packages.package.duration.split(':');
        const finalLabel = `${duration[0]} hora(s) y ${duration[1]} minuto(s)`;
        const total = this.formatter.format(packages.package.price);
        const discount = this.formatter.format(packages.package.subtotal);
        const date = packages.package.date_purchased.split('-');
        this.datePurchased = `${date[2]}-${date[1]}-${date[0]}`;
        this.areasNames = packages.package.areasSessions;
        this.getAreasInfo(packages.package.areasSessions);
        this.getPaymentsInfo(packages.package.payments_data);
        this.commentsSessions = packages.package.session_comments;
        this.imageContract = packages.package.contract;

        this.formPackages.patchValue({
          name: packages.package.client_name,
          areas: packages.package.areas,
          sessions: `${packages.package.sessionNumber} sesiones`,
          durations: finalLabel,
          total: total,
          discount: discount,
          applyDiscount: packages.package.percentage,
          observations: packages.package.observations,
        });
      },
      error: (err) => {},
    });
  }

  displayContract() {
    this.modalContractClose = this.modal.open(this.contractModal, {
      size: 'lg',
    });
  }

  close(): void {
    this.modalContractClose.close();
  }
}
