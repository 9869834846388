<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Lista de Nominas</h3>
  </div>
  <hr />
  <div class="row align-items-center mb-3">
    <div class="col-md-12">
      <div class="d-grip gap-1 d-md-flex justify-content-md-end">
        <a [routerLink]="['/employees/create']" class="btn btn-outline-success">
          <i class="bi bi-plus-circle"></i>
          Generar Nomina
        </a>
      </div>
    </div>
  </div>
  <hr />

  <ngb-accordion [closeOthers]="true" activeIds="static-0" class="mt-3">
    <ngb-panel
      *ngFor="let report of payrolls; let i = index"
      id="static-{{ i }}"
      title="Nomina del {{ report.cycle_date_start }} al {{
        report.cycle_date_end
      }}"
    >
      <ng-template ngbPanelContent>
        <div class="accordion-body">
          <table class="table table-striped">
            <thead class="table-dark">
              <tr>
                <td>Colaborador</td>
                <td>Email</td>
                <td>Telefono</td>
                <td>Total</td>
                <td>Opciones</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let employee of report.employees">
                <td>{{ employee.user.name }}</td>
                <td>{{ employee.user.email }}</td>
                <td>{{ employee.user.phone }}</td>
                <td>{{ employee.total_paid | currency }}</td>
                <td>
                  <div class="me-2">
                    <a
                      placement="top"
                      ngbTooltip="Motrar Nomina"
                      role="button"
                      class="btn btn-primary"
                    >
                      <i class="bi bi-eye-fill"></i>
                    </a>
                    <a
                      class="btn btn-primary ms-2"
                      placement="top"
                      ngbTooltip="Descargar Nomina"
                      role="button"
                    >
                      <i class="bi bi-file-earmark-arrow-down-fill"></i>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
