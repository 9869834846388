<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Informacion del paquete</h3>
  </div>
  <hr />
  <div class="d-grip gap-1 d-md-flex justify-content-md-end mb-3">
    <a [routerLink]="['/packages/list']" class="btn btn-outline-success">
      <i class="bi bi-arrow-left-circle"></i>
      Regresar a lista de paquetes
    </a>
  </div>
  <hr />
  <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
    <form [formGroup]="formPackages">
      <div class="accordion" id="packages_sessions">
        <div class="accordion-item">
          <h2 class="accordion-header" id="package-information">
            <button
              class="accordion-button color-header-collapse"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              disabled
            >
              <span class="color-text"> Creaci&oacute;n del Paquete </span>
              <span style="margin-left: 42.5rem !important">
                Fecha de compra: {{ datePurchased }}
              </span>
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div class="row">
                <div class="form-floating mb-1 col-md-6">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    formControlName="name"
                    class="form-control"
                    placeholder="Nombre del cliente"
                    required
                    readonly
                  />
                  <label for="name" class="ms-2">Nombre del cliente</label>
                </div>
                <div class="form-floating mb-1 col-md-6">
                  <input
                    type="text"
                    name="sessions"
                    id="sessions"
                    formControlName="sessions"
                    class="form-control"
                    placeholder="Numero de sesiones"
                    required
                    readonly
                  />
                  <label for="sessions" class="ms-2">Numero de sesiones</label>
                </div>
              </div>

              <div class="row mb-2">
                <div class="form-floating mt-2 col-md-12">
                  <input
                    type="text"
                    name="areas"
                    id="areas"
                    formControlName="areas"
                    class="form-control"
                    placeholder="Areas del paquete"
                    required
                    readonly
                  />
                  <label for="areas" class="ms-2">Areas del paquete</label>
                </div>
              </div>

              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Sesiones por area</h5>
                  <hr />
                  <div class="row mb-2" *ngFor="let fields of areasNames">
                    <div class="col-md-6 form-floating">
                      <input
                        type="text"
                        name="custom-area-{{ fields.value }}"
                        id="custom-area-{{ fields.value }}"
                        class="form-control"
                        placeholder="Area"
                        value="{{ fields.area }}"
                        readonly
                      />
                      <label for="" class="ms-2"> Area </label>
                    </div>
                    <div class="col-md-6 form-floating">
                      <input
                        type="text"
                        name="custom-session-{{ fields.sess_id }}"
                        id="custom-session-{{ fields.sess_id }}"
                        class="form-control"
                        placeholder="Numero de Sesiones"
                        value="{{ fields.number }}"
                        readonly
                      />
                      <label for="" class="ms-2"> Numero de Sesiones </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-md-6">
                  <label for="total" class="ms-2">Total</label>
                  <div class="input-group">
                    <span class="input-group-text">$</span>
                    <input
                      type="text"
                      name="total"
                      id="total"
                      formControlName="total"
                      class="form-control"
                      placeholder="Total"
                      required
                      readonly
                    />
                  </div>
                </div>
                <div class="form-floating mb-1 col-md-6">
                  <input
                    type="text"
                    name="durations"
                    id="durations"
                    formControlName="durations"
                    class="form-control"
                    placeholder="Duraci&oacute;n del paquete"
                    required
                    readonly
                  />
                  <label for="durations" class="ms-2">
                    Duraci&oacute;n del paquete
                  </label>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-6">
                  <label for="total" class="ms-2">Total con descuento</label>
                  <div class="input-group">
                    <span class="input-group-text">$</span>
                    <input
                      type="text"
                      name="discount"
                      id="discount"
                      formControlName="discount"
                      class="form-control"
                      placeholder="Total con descuento"
                      required
                      readonly
                    />
                  </div>
                </div>
                <div class="mb-1 col-md-6">
                  <label for="durations" class="ms-2">
                    Descuento aplicado %
                  </label>
                  <div class="input-group">
                    <input
                      type="text"
                      name="applyDiscount"
                      id="applyDiscount"
                      formControlName="applyDiscount"
                      class="form-control"
                      placeholder="Descuento aplicado %"
                      required
                      readonly
                    />
                    <span class="input-group-text">%</span>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-{{ columnsPayments }} form-floating">
                  <input
                    type="text"
                    name="paymentMethodId"
                    id="paymentMethodId"
                    formControlName="paymentMethodId"
                    class="form-control"
                    placeholder="Metodo de pago"
                    readonly
                  />
                  <label for="" class="ms-2"> Metodo de pago </label>
                </div>
                <div class="col-md-{{ columnsPayments }} form-floating">
                  <input
                    type="text"
                    name="paymentId"
                    id="paymentId"
                    class="form-control"
                    placeholder="Opciones de pago"
                    formControlName="paymentId"
                    readonly
                  />
                  <label for="" class="ms-2"> Opciones de pago </label>
                </div>
                <div
                  class="col-md-{{ columnsPayments }} form-floating"
                  *ngIf="terminalFlag"
                >
                  <input
                    type="text"
                    name="terminal"
                    id="terminal"
                    class="form-control"
                    placeholder="Terminal"
                    formControlName="terminal"
                    readonly
                  />
                  <label for="" class="ms-2"> Terminal </label>
                </div>
              </div>

              <div class="row mt-2">
                <div class="form-floating">
                  <textarea
                    class="form-control"
                    placeholder="Observaciones"
                    id="floatingTextarea"
                    formControlName="observations"
                    style="height: 100px"
                    readonly
                  ></textarea>
                  <label for="floatingTextarea" class="ms-2">
                    Observaciones
                  </label>
                </div>
              </div>
              <div class="row mt-2">
                <button
                  class="btn btn-success"
                  type="button"
                  (click)="displayContract()"
                >
                  Abrir imagen del contrato
                </button>
              </div>
              <hr />
              <div *ngIf="commentsSessions.length > 0">
                <label class="fw-bold" for="title_comments"
                  >Comentarios de las sesiones</label
                >
                <div class="card mb-2" *ngFor="let comment of commentsSessions">
                  <div class="card-body">
                    <p style="line-height: 0.5rem">
                      <strong>Fecha de sesi&oacute;n:</strong>
                      {{ comment.dateComment }}
                    </p>
                    <p style="line-height: 0.5rem">
                      <strong>Numero de sesion:</strong>
                      {{ comment.numberSession }}
                    </p>
                    <p
                      style="line-height: 0.5rem"
                      *ngIf="comment.comment.length > 0"
                    >
                      <strong>Comentario:</strong>
                      {{ comment.comment }}
                    </p>
                    <p
                      style="line-height: 0.5rem"
                      *ngIf="comment.finalComment.length > 0"
                    >
                      <strong>Comentario Final:</strong>
                      {{ comment.finalComment }}
                    </p>
                    <p
                      style="line-height: 0.5rem"
                      *ngIf="
                        (comment.comment.length == 0 || !comment.comment) &&
                        (comment.finalComment.length == 0 ||
                          !comment.finalComment)
                      "
                    >
                      <strong>No se realizo ningun comentario.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3 mt-2 d-grip gap-2 d-md-flex justify-content-md-end">
          <a [routerLink]="['/packages/list']" class="btn btn-outline-success">
            <i class="bi bi-arrow-left-circle"></i>
            Regresar a lista de paquetes
          </a>
        </div>
      </div>
    </form>
  </div>
</div>

<ng-template #contractModal let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">Contrato</h4>
    <button class="close" type="button" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img src="{{ imageContract }}" class="card-img-top" />
  </div>
</ng-template>
